@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};


.main {
  background-color: #fff;
  position: relative;
 
  
  .main__box1 {
    width: 100%;
    min-height: 100vh;
    padding-top: 20px;
    background-image: url('../../images/mainimage.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  
    .main_top_container {
      width: 100%;
      min-height: 40vh;
      display : flex;
      flex-direction : column;
      align-items : center;
      color : #fff;

      .main__box-slogan {
        display : flex;
        flex-direction : column;
        text-align: center;
        margin-top : 30px;
        margin-bottom: 20px;

        span {
          font-size: 40px;
        }
      }

    }

    .main_middle_cover {
      width: 100%;
      min-height: 30vh;
      display: flex;
      justify-content: space-between;
      
      .main_bottom_box {
        width: 32.5%;
        height: 20vh;
        border: 1px solid #fff;
        color: #fff;
        padding: 10px;
        box-sizing: border-box;
        transition: transform 0.7s ease;

        .main_bottom_text {
          height: 80%;
          border-bottom: 1px solid #fff;

          h1 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          p {

          }
        }
        .main_bottom_link {
          width: 100%;
          height: 20%;
          display: flex;
          align-items: center;
          justify-content: end;
        }
      }
      .main_bottom_box:hover {
        cursor: pointer;
        transform: scale(1.03);
      }
    }
    .main_bottom_cover {
      min-height: 30vh;
      
    }
  

  }

     
}


@media screen and (max-width : 1000px) { 

  .main {


    .main__box1 {
     
      .main_bottom_cover {
               
        .main_bottom_box {
          width: 31%;
            
          .main_bottom_text {
            

            h1 {
              font-size: 18px;
            }
            p {
              font-size: 14px;
            }
          }
          .main_bottom_link {
            
          }
        }
        .main_bottom_box:hover {
          
        }
      }

      .kakaoBtnBox {
        display: none;
      }

     
    }
    
    
  }
}



@media screen and (max-width : 600px) { 

  .main {

   
    .main__box1 {
      
      .main__box-container {
        margin-bottom: 50px;
      }
      
      .main_middle_cover {
        min-height: 230px;
        padding-bottom: 20px;

        .main_bottom_box {
          min-height: 230px;
            
          .main_bottom_text {
            height: 85%;
            border-bottom: none;

            h1 {
              font-size: 16px;
            }
            p {
              font-size: 14px;
            }
          }
          .main_bottom_link {
            height: 15%;
          }
        }
   
      }
      .main_bottom_cover {
        min-height: 45vh;
      }
    }
  }
}