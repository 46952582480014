@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};

.portfolio {
  padding-top: 50px;

  > .inner {
    display: flex;
    justify-content: space-between;

    
  
    .subpage__main {
      width: 100%;
      min-height: 100vh;

      .subpage__main__title {
        display: flex;
        justify-content: space-between;
        
        h3 {
          font-size: 30px;
          font-weight: 600;
          color: #33383f;
        }
        .postBtnbox {
          padding: 10px;
          border: 1px solid #cbcbcb;
          border-radius: 5px;
        }
        .postBtnbox:hover {
          cursor: pointer;
          background-color: #ececec;
        }
      }

      
          

      
      .subpage__main__content {
          padding-top: 30px;
          font-size: 20px;
          color: #333;
          margin-bottom: 100px;

        
          .board .subpage__main__content {
            font-size: 16px;
          }
          
          .main__title {
              position: relative;
              font-size: 26px;
              color: #0f386e;
              padding: 24px 0;
              margin-bottom: 20px;
              word-break: keep-all;
              line-height: 1.54;
          }
          
          .main__title:after {
              position: absolute;
              content: '';
              width: 28px;
              height: 3px;
              background: #0f386e;
              left: 0;
              bottom: 0;
          }
         
          .main__content {
            line-height: 1.9;
  
            .place__wrap--category {
              
              .place__title__row {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-bottom: 3px solid #f1f1f1;

                .place__title {
                  padding: 8px 0;
                  color: #000;
                  font-size: 24px;
                  line-height: 1;
                  font-weight: 500;
                  text-align: left;
                }
              }
              .place__wrap--item {
                display: grid;
                justify-content: space-between;
                grid-template-columns: 33% 33% 33% ;
                padding: 20px;
  
                .place__item {
                  width: 100%;
                  text-align: center;
                  padding: 0 20px 20px 0;
                  box-sizing: border-box;
                  border-bottom: 1px solid #EAEAEA;
                  margin: 20px 10px;
                  transition: transform 0.7s ease;
  
                  .place__img--cover {
                    position: relative;
                    border: 1px solid #EAEAEA;
                    margin-bottom: 10px;
                    border-radius: 5px;
  
                    .imageBox {
                      width:100%;
                      height: 250px; 
                      background-color:#fff;
                      @include middle();
                      border-radius: 5px;
  
                      img {
                        width: 100%;
                        max-height: 250px;
                        object-fit: contain;
                      }
                    }
                  }
                  .place__coname {
                    font-size: 18px;
                    font-weight: 600;
                    text-align: left;
                  }
                  .place__name {
                    font-size: 14px;
                    text-align: left;
                  }
                  
                }
                .place__item:hover { 
                  border-bottom: 1px solid #0f386e;
                  cursor: pointer;
                  transform: scale(1.05);
                }
              }
             
            }
            .imagearea {
              width: 100%;

              .mainimage {
                max-width: 100%;
                height: 500px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                
                img {
                  max-width: 100%;
                  height: 500px;
                }
              }
              
              
      
            }
            .textrow {
              display: flex;
              margin: 10px 0;
        
              h3 {
                width: 20%;
                font-size: 16px;
              }
              p {
                width: 80%;
                font-size: 16px;
              }
              .textrow__link {
                width: 80%;
                font-size: 16px;
                text-decoration: underline;
                flex-wrap: wrap;
                overflow: hidden;
              }
            }
            .divider {
              width: 100%;
              height: 1px;
              background-color: #0f386e;
              margin: 20px 0;
            } 
            .mobile {
              display: none;
            }
          }
        
      
       
        .btn-row {
          width: 100%;
          margin: 20px 0;
          @include middle();

          .btn {
            width: 40px;
            height: 40px;
            border-radius: 20px; 
            cursor: pointer; 
            margin: 10px;
            
            @include middle();
          }
        }

      }
      
    }
  }
}

@media (max-width: 1000px) { 
  .portfolio {
  
    > .inner {
      flex-direction: column;

      
      .subpage__main { 
        width: 100%;

        .subpage__main__title {
          margin-top: 30px;
          margin-bottom: 20px;
        }
       

        .subpage__main__content {
          font-size: 14px;
          padding-top: 14px;

          .main__content {
             
            .place__wrap--category {
  
              .place__title {
             
              }
              .place__wrap--item {
                grid-template-columns: 30% 30% 30%;

                .place__item {
  
                  .place__img--cover {
                   
                    .imageBox {
                      height: 200px; 
 
                      img {
                        max-height: 200px;
                      }
                    }
                  }
                                   
                }
              }
            }
          }

         
          .top_box {
           
          
            .left {
              
              h1 {
                font-size: 18px;
              }
              p {
                font-size: 15px;
              }
            }
  
            .right {
              

              .contentcover {

                .box {
                  margin-left: 5px;

                  p {
                    font-size: 13px;
                    margin-left: 2px;
                  }
                }
                
              }
            }
         
          }
          .view_content {
            
            
            .textcover {
  
              p {
                font-size: 16px;
              }
            }
            .btn-box {
  
              .btn {
                
                p {

                }
              }
            }
          }
          .warningBox {
            font-size: 14px;
          }

          .userBox {
            p {
              font-size: 18px;
            }
          }
        }

      }
    }
  }
}




@media (max-width: 600px) { 
  .portfolio {
  
    > .inner {

      .subpage__main { 


        .subpage__main__content {

          .main__content {
             
            .place__wrap--category {
  
              .place__title {
             
              }
              .place__wrap--item {
                padding: 0;
                grid-template-columns: 50% 50%;

                .place__item {
  
                  .place__img--cover {
                   
                    .imageBox {
                      height: 170px; 
 
                      img {
                        max-height: 170px;
                      }
                    }
                  }
                                   
                }
              }
            }
          }
          
        }

      }
    }
  }
}



